import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import LogService from '../services/LogService';
import { restructureLogList } from '../utils/logFunctions';


class LogStore {
    loading: boolean = false;
    logList: any[] = [];
    logUser: any[] = [];
    logProduct: any[] = [];
    logCampaing: any[] = [];
    logCupom: any[] = [];
    logBannerFile: any[] = [];
    logPrices: any[] = [];
    logChannel: any[] = [];
    logOrder: any[] = [];
    logBanner: any[] = [];
    logCustomer: any[] = [];

    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getListUser(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.logUser = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            } else {
                this.logUser = [];
            }
            this.loading = false;
        });

        return response;
    }

    async getListProduct(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.logProduct = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            } else {
                this.logProduct = [];
            }
            this.loading = false;
        });

        return response;
    }

    async getListCampaing(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.logCampaing = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            } else {
                this.logCampaing = [];
            }
            this.loading = false;
        });

        return response;
    }

    async getListCupom(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.logCupom = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            } else {
                this.logCupom = [];
            }
            this.loading = false;
        });

        return response;
    }

    async getListBannerFile(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.logBannerFile = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            } else {
                this.logBannerFile = [];
            }
            this.loading = false;
        });

        return response;
    }


    async getListBanner(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.logBanner = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            } else {
                this.logBanner = [];
            }
            this.loading = false;
        });

        return response;
    }


    async getListPrices(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.logPrices = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            } else {
                this.logPrices = [];
            }
            this.loading = false;
        });

        return response;
    }

    async getListChannel(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.logChannel = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            } else {
                this.logChannel = [];
            }
            this.loading = false;
        });

        return response;
    }

    async getListOrder(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.logOrder = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            } else {
                this.logOrder = [];
            }
            this.loading = false;
        });

        return response;
    }


    async getListCustomer(params: any) {
        this.loading = true;
        const response = await LogService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.logCustomer = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            } else {
                this.logCustomer = [];
            }
            this.loading = false;
        });

        return response;
    }
}

const logStore = new LogStore();
export default logStore;