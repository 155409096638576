import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, message, Space, Table, Tag } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import { PermissionHome } from '../../../permissions/PermissionHome';
import homeStore from '../../../stores/homeStore';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { buildSearchString, getSortField } from '../../../utils/functions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import PermissionComponent from '../../atoms/PermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import ModalComponent from '../../organisms/ModalComponent';
import { useAuth } from '../../protected/ProviderAuth';

interface DataType {
    active: boolean | React.Key;
    code: string;
    name: string;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    name?: string;
    code?: string;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Listagem de Home</p>,
    },
]

const ListSubChannel: React.FC = observer(() => {
    const { homeList, loading, page, totalElements, defaultparams, sort } = homeStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const [selectionCheckbox, setSelectionCheckbox] = useState<any>();
    const [queryParams, setQueryParams] = useQueryParams();
    const navigate = useNavigate();
    const { hasPermission, isColumnVisible } = useAuth();

    const initialValues = {
        name: queryParams.name || '',
        code: queryParams.code || '',
    };

    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
        name: 'name',
        code: 'code',
    };

    useEffect(() => {
        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            if (hasPermission(PermissionHome.GET)) {
                await homeStore.getList(params);
            }
        };
        fetchData();
    }, []);


    const getHomeType = (action: string): string => {
        switch (action) {
            case 'channel':
                return 'Canal';
            case 'subChannel':
                return 'SubCanal';
            case 'customer':
                return 'Cliente';
            case 'default':
                return 'Padrão';
            default:
                return 'Default';
        }
    };

    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Tipo',
            dataIndex: 'model',
            key: 'model',
            render: (text) => {
                return (
                    //@ts-ignore
                    <>{<p>{getHomeType(text)}</p>}</>
                );
            },
        },
        {
            title: 'Data Inicial',
            dataIndex: 'initialDate',
            key: 'initialDate',
            render: (text) => {
                return (
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Data Inicial',
            dataIndex: 'finalDate',
            key: 'finalDate',
            render: (text) => {
                return (
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'finalDate',
            key: 'finalDate',
            render: (text) => {
                const today = new Date(); // Data e hora atuais
                const finalDate = new Date(text); // Data final

                // Verifica se a data final já passou em relação à data de hoje
                const isExpired = finalDate < today;

                return (
                    <>
                        <Tag color={isExpired ? "red" : "green"}><p>{isExpired ? 'Vencido' : 'Ativo'}</p></Tag>
                    </>
                );
            },
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionHome.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/home/editar/${record.uuid}`}>
                        <div><EditOutlined /></div>
                    </a>
                </Space>
            ),
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionHome.DELETE),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];



    const handleSubmit = async (values: { name: string; code: string }) => {
        const newParams: any = {
            name: values.name || undefined,
            code: values.code || undefined,
        };

        const search = [
            values.name ? `name;"${values.name}"` : null,
            values.code ? `code;"${values.code}"` : null,
        ]
            .filter(Boolean)
            .join(' ');

        const params = {
            size: queryParams.size ? Number(queryParams.size) : DEFAULT_PAGE_SIZE,
            page: queryParams.page ? Number(queryParams.page) : 0,
            sort: queryParams.sort || sort,
            search,
        };

        setQueryParams(newParams);

        //@ts-ignore
        await homeStore.getList(params);
    };


    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm(); // Limpa os valores do formulário
        setQueryParams({}); // Limpa a URL
        await homeStore.getList(defaultparams);

        const newInitialValues = {
            name: '',
            code: '',
        };

        //@ts-ignore
        resetForm(newInitialValues);
    };

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = async () => {
        const response = await homeStore.deleteHome(itemSelected.uuid);
        if (!response?.error) {
            await homeStore.getList(defaultparams);
            setIsModalOpen(false);
        }
    };

    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await homeStore.getList(params);
    };


    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const filters = {
            name: queryParams.name,
            code: queryParams.code,
        };

        const search = buildSearchString(filters, ['name', 'code']);

        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            sort: getSort || queryParams.sort || sort, // Use o sort da tabela se disponível
            search: search || queryParams.search || '', // Mantenha a busca da URL
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
            search: params.search,
        });

        return params;
    };



    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectionCheckbox(selectedRows);
        },
    };


    const deleteSelectedItems = async () => {
        try {
            if (selectionCheckbox !== undefined) {
                const results = await Promise.all(
                    selectionCheckbox.map((item: any) => homeStore.deleteHome(item.uuid))
                );
                const hasError = results.some(response => response?.error);
                if (hasError) {
                    message.error('Erro ao deletar um ou mais Homes.');
                    setSelectionCheckbox([]);
                } else {
                    message.success('Todos as Homes foram deletados com sucesso!');
                    await homeStore.getList(defaultparams);
                    setSelectionCheckbox([]);
                }
                setIsModalDeleteOpen(false);
            } else {
                message.error('Selecione pelo menos um item da listagem');
            }
        } catch (error) {
            message.error('Erro ao tentar deletar os canais.');
        }
    };

    const handleExport = async () => {
        const params = buildParamsFromQuery(queryParams, mappings);
        const exportParams = {
            ...params,
            size: 999999,
        }

        const dataExport = await homeStore.getList(exportParams);

        if (dataExport) {
            const listExport = dataExport.map((item: any) => ({
                name: item.name,
                model: getHomeType(item.model),
                initialDate: formatDateString(item.initialDate),
                finalDate: formatDateString(item.finalDate),
                created: formatDateString(item.created?.toString() ?? ""),
                ativo: item.active ? "Sim" : "Não",
            }));

            exportToExcel(listExport, ["Nome", "Tipo", "Data Inicial", "Data Final", "Data de Criação", "Status"], "Tabela de Home.xlsx");
        }
    };
    const listRouter = () => {
        navigate(`/home/cadastro`);
    }

    const deleteAllModal = () => {
        if (selectionCheckbox?.length > 0) {
            setIsModalDeleteOpen(true);
        } else {
            message.warning('Por favor, selecione ao menos um item para realizar a exclusão.');
        }
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionHome.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            <Permission permissionKeys={[PermissionHome.GET]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-4 p-6" title="Home" description='Tela de listagem de Homes'>
                    {/* @ts-ignore */}
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                        {({ resetForm }) => (  // Passa o resetForm do render prop de Formik
                            <Form>
                                <div className="flex items-end w-full gap-4">
                                    <div className="w-full">
                                        <InputComponet label="Nome" name="name" id="name" />
                                    </div>
                                    <div className="col-span-12 md:col-span-2 flex flex-row gap-4">
                                        <Button onClick={() => handleClearAndSearch(resetForm)}>Limpar</Button>
                                        <Button htmlType="submit" type="primary">Filtrar</Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ContentBox>

                <ContentBox className="mb-4 p-6" title="Home" description='Listagem de Homes' actions={
                    <>
                        <Permission permissionKeys={[PermissionHome.DELETE]}><ActionButton icon actionType="delete" onClick={() => deleteAllModal()}>Deletar</ActionButton></Permission>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={homeList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        onChange={onChange}
                        loading={loading}
                        rowSelection={rowSelection}
                        size="middle"
                        bordered={false}
                        pagination={false}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>

            {isModalOpen && <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o Canal <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />}

            {isModalDeleteOpen && <ModalComponent
                title="Aviso"
                content={<p>Você está prestes a excluir vários itens da listagem, deseja prosseguir?</p>}
                isModalOpen={isModalDeleteOpen}
                handleOk={deleteSelectedItems}
                handleCancel={() => setIsModalDeleteOpen(false)}
            />}
        </>
    );
});

export default ListSubChannel;