import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import channelStore from '../../../../stores/channelStore';
import homeStore from '../../../../stores/homeStore';
import subChannel from '../../../../stores/subChannel';
import DatePickerRangeComponet from '../../../atoms/DatePickerRangeComponet';
import InputComponent from '../../../atoms/InputComponet';
import SelectComponent from '../../../atoms/SelectComponent';
import ContentBox from '../../../molecules/ContentBox';
import CustomerAddHome from '../CustomerAddHome';
import { useParams } from 'react-router-dom';
import Permission from '../../../molecules/Permission';
import { PermissionChannel } from '../../../../permissions/PermissionChannel';
import { PermissionSubChannel } from '../../../../permissions/PermissionSubChannel';

export const modelOptions = [
    { value: 'channel', label: 'Canal' },
    { value: 'subChannel', label: 'SubCanal' },
    { value: 'customer', label: 'Cliente' },
    { value: 'default', label: 'Padrão' },
];

const GeneralInfomation: React.FC = observer(() => {
    const { selectSubChannelList } = subChannel;
    const { selectChannelList } = channelStore;
    const { homeProps } = homeStore;
    const { uuid } = useParams<{ uuid: string }>();

    const selectedModel = (item: any) => {
        homeStore.updateModel(item.value);
    }

    return (
        <div>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Informações Gerais" description='Tela para cadastro de home'>
                <div className="w-full flex flex-col gap-2">
                    <div className="flex gap-4">
                        <InputComponent label="Nome" name="name" id="name" />
                    </div>

                    <div className="flex gap-4">
                        <SelectComponent disabled={uuid ? true : false} onChange={(value, option) => selectedModel(option)} label="Tipo" name="model" options={modelOptions} />

                        {homeProps?.model === 'channel' &&
                            <Permission permissionKeys={[PermissionChannel.GET]} inputType name="Canal">
                                <SelectComponent label="Canal" name="channel" options={selectChannelList} />
                            </Permission>
                        }

                        {homeProps?.model === 'subChannel' &&
                            <Permission permissionKeys={[PermissionSubChannel.GET]} inputType name="SubCanal">
                                <SelectComponent label="SubCanal" name="subChannel" options={selectSubChannelList} />
                            </Permission>
                        }
                    </div>

                    <div className="flex gap-4">
                        <DatePickerRangeComponet label="Data Inicial e Final" name="date" />
                    </div>
                </div>
            </ContentBox>

            {homeProps?.model === 'customer' &&
                <CustomerAddHome />
            }
        </div>
    );
});

export default GeneralInfomation;